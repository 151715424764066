.authBox {
  width: 50%;
  height: 100vh;
  float: right;
}

.authSideBox {
  width: 50%;
  height: 100vh;
  float: left;
  background-color: #f0f3f5;
  object-fit: cover;
  object-position: 100% 50%;
}

.authInner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.loginForm {
  max-width: 40rem;
  width: 40vw;
}

@media (max-width: 768px) {
  .authBox {
    width: 100%;
  }

  .authSideBox {
    display: none;
  }

  .loginForm {
    max-width: 80rem;
    width: 80vw;
  }
}
