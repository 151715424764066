@import "react-toastify/dist/ReactToastify.css";

.ant-menu-item-group-title {
  font-weight: 600;
  text-transform: uppercase;
}

* {
  font-family: "Poppins", sans-serif !important;
}

body {
  background-color: #f0f3f5;
}

.login-main-logo {
  text-align: center;
  height: 20vh;
  color: #1677ff;
  bottom: 0;
  line-height: 30vh;
  font-size: 250%;
}

.table-last-cell {
  background-color: white;
}

.desktop-sidebar-item {
  width: 90%;
  margin: 5%;
  border: 0 !important;
  background-color: #f0f3f5;
}

.mobile-sidebar-item {
  width: 100%;
  margin: 0;
  border: 0;
  background-color: #f0f3f5;
}

.no-auth-language-select {
  background-color: #f0f3f5;
  width: 15vh;
  right: 0;
  top: -2.5vh;
  position: absolute;
}

.ellipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  max-width: 150px; /* Adjust the width as needed */
}

.sidebar {
  background-color: #f0f3f5 !important;
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06) !important;
  height: 100vh !important;
}

.desktop-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  flex: 1;
}

.services-columns,
.data-connection-column {
  width: 100%;
  max-width: 400px;
  margin-right: 20px;
  margin-left: 20px;
}

.feedback-column {
  min-width: 500px;
  margin-right: 20px;
  margin-left: 20px;
}

.main-content {
  padding-left: 5%;
  background-color: #f0f3f5;
  padding-right: 5%;
  max-width: 3000px;
}

.ant-card {
  border: solid 1px #dfe6e9 !important;
}

.ant-layout {
  background-color: #f0f3f5;
}

.dashboard-services {
  max-width: 90%;
}

.header {
  background-color: transparent;
}

.sidebar-wrapper {
  transition: width 0.2s ease-in-out;
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
}

.data-connection-card {
  height: 175px;
}

.data-connection-logo {
  height: 40px;
  width: auto;
}

.service-logo {
  height: 30px;
  margin: 3px;
  width: auto;
}

.right-arrow {
  padding-top: 10px;
  height: 15px;
  width: auto;
}

.right-arrow-services {
  padding-top: 15px;
  height: 20px;
  width: auto;
}

.list-item-text {
  width: 100%;
  margin: 10px;
  float: left;
  text-align: left;
}

.popover_box {
  max-width: 400px;
}

.help-card {
  margin-bottom: 20px;
}

.help-image-tiny {
  max-height: 100px;
  width: auto;
}

.help-image-small {
  max-height: 200px;
  width: auto;
}

.help-image {
  max-height: 400px;
  width: auto;
}

.help-card {
  margin-bottom: 20px;
}

.help-image-tiny {
  max-height: 100px;
  width: auto;
}

.help-image-small {
  max-height: 200px;
  width: auto;
}

.help-image {
  max-height: 400px;
  width: auto;
}

@media (max-width: 1584px) {
  .dashboard-services {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .main-content {
    padding-bottom: 7rem;
  }
}

@media (max-width: 1368px) {
  .main-content {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media (min-width: 769px) {
  .drawer-button {
    display: none;
  }
}

@media (max-width: 768px) {
  .calendar-popup {
    position: absolute;
    left: 0 !important;
  }

  .ant-picker-panels {
    display: inline !important;
  }

  .ant-picker-panel-container {
    margin-left: 0 !important;
  }

  .ant-picker-panel-layout {
    margin: auto;
    width: 80%;
  }
}

.custom-404-title .ant-result-title {
  font-size: 20vw; /* or any size you prefer */
}

.dashboard-services-info {
  .price-card {
    max-width: 350px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 100%;
  }

  img {
    height: 50px;
    width: auto;
  }
}
